// ScrollMemory.jsx
import React from 'react';
import PropTypes from 'prop-types';

import withRouter from '../component/WithRouter.jsx';

import { isClient } from '../resource/getUserAgent.js';

export class ScrollMemory extends React.Component {
  url = new Map();

  tabKey = ['search', 'searchHashTag', 'userProfile'];
  memoryList = {
    home: /^\/$/,
    homeVideo: /^\/video$/,
    homeStory: /^\/story$/,
    search: /\/search\/.+/,
    searchHashTag: /\/hashtag\/.+\/.+/,
    userProfile: /\/user\/.+\/.+/,
    feed: /\/feed/,
    following: /\/following/,
    flixCategories: /^\/flix-categories/,
    userCategories: /^\/user-categories/,
    storyCategories: /^\/story-categories/,
  };

  locationChangedRecoverList = {
    home: /^\/$/,
    homeVideo: /^\/video$/,
    homeStory: /^\/story$/,
    feed: /\/feed/,
    following: /\/following/,
    flixCategories: /^\/flix-categories/,
    userCategories: /^\/user-categories/,
    storyCategories: /^\/story-categories/,
  };

  notScrollTopList = {
    leaderBoard: /\/leaderboard\/global\/.+\/.+/,
  };
  scrollTo = scrollNumber => {
    window.setTimeout(() => {
      window.scrollTo({ top: scrollNumber || 0, behavior: 'auto' });
    }, 100);
  };

  getScrollPage = () => {
    const docScrollTop = document?.documentElement?.scrollTop || 0;
    return window.pageYOffset || docScrollTop;
  };

  getIsInList = (list, pathname) =>
    Object.keys(list).reduce(
      (result, key) => (result ? result : list[key].test(pathname)),
      false
    );

  getUserIdByPathname = pathname => pathname.split('/')[2];

  checkIsSameUser = (actual, next) =>
    this.getUserIdByPathname(actual.pathname) ===
    this.getUserIdByPathname(next.pathname);

  getIsTabSwitch = (actual, next) =>
    this.tabKey.reduce((result, key) => {
      if (key === 'userProfile' && !this.checkIsSameUser(actual, next))
        return result;
      const isTabSwitch =
        this.memoryList[key].test(actual.pathname) &&
        this.memoryList[key].test(next.pathname);
      return result ? result : isTabSwitch;
    }, false);

  locationChangedHandler = (actual, next) => {
    !this.getIsInList(this.notScrollTopList, next.pathname) && this.scrollTo(0);

    // get scroll of the page or the element before change location
    const scroll = this.getScrollPage();

    const shouldScroll =
      this.getIsInList(this.locationChangedRecoverList, next.pathname) ||
      this.getIsTabSwitch(actual, next);
    const shouldMemory = this.getIsInList(this.memoryList, actual.pathname);

    if (shouldScroll) {
      // scroll when locationChanged
      this.scrollTo(this.url.get(next.pathname) || 0);
    }

    // save scroll with pathname
    if (shouldMemory) {
      if (this.url.size >= 50) this.url.clear();
      this.url.set(actual.pathname, scroll);
    }
  };

  shouldComponentUpdate(nextProps) {
    if (!isClient) return false;
    const { location } = this.props;
    // location before change url
    const actual = location;
    // location after change url
    const next = nextProps.location;

    // if hash => let the normal operation of the browser
    const locationChanged =
      (next.pathname !== actual.pathname || next.search !== actual.search) &&
      next.hash === '';

    if (locationChanged) {
      this.locationChangedHandler(actual, next);
    }
    // never render
    return false;
  }

  detectPop = () => {
    if (!isClient) return;
    const { location } = this.props;
    // get the next for scroll position
    const nextFind = this.url.get(location.pathname) || 0;

    !this.getIsInList(this.notScrollTopList, location.pathname) &&
      this.scrollTo(nextFind);
  };

  componentDidMount() {
    window.addEventListener('popstate', this.detectPop);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.detectPop);
  }

  render() {
    return null;
  }
}

ScrollMemory.propTypes = {
  location: PropTypes.object,
};

ScrollMemory.defaultProps = {
  location: {},
};

export default withRouter(ScrollMemory);
