// updateLanguage.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import removeShopCurrency from './removeShopCurrency.js';
import i18n, { localeConvertor } from '../resource/i18n.js';
import { setRemoteMeta } from '../resource/mixpanel.js';
import { getSupportedLanguage } from '../resource/getCurrentLanguage.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { SUPPORTED_LANGUAGES } from '../RemoteConfigKeys.js';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Update language (both i18n.language and redux)
 * @kind action
 * @param {string} {language} - action param.
 * @return {Promise} Action promise.
 */
const updateLanguage =
  ({ language }) =>
  async (dispatch, getState) => {
    const lang = getSupportedLanguage({
      language,
      supportedLanguages: getRemoteConfigData(getState(), SUPPORTED_LANGUAGES),
    });

    i18n.changeLanguage(lang, error => {
      if (!error) {
        setRemoteMeta({ data: {} });
      }
    });

    dispatch(removeShopCurrency());

    sendMessageToSW({
      type: MessageTypes.SET_AUTHORIZER_LANGUAGE,
      payload: {
        language: localeConvertor({ locale: language, isISO639: true }),
      },
    });
    // re-subscribe presence-enc-client@{CLIENT_ID}
    const clientId = getMeData(getState(), 'clientId');
    sendMessageToSW({
      type: MessageTypes.SUBSCRIBE_PRESENCE_CLIENT_CHANNEL,
      payload: {
        clientId,
      },
    });
    // re-subscribe presence-enc-user@{USER_ID}
    const meId = getMeData(getState(), 'id');
    sendMessageToSW({
      type: MessageTypes.SUBSCRIBE_PRESENCE_USER_CHANNEL,
      payload: {
        userId: meId,
      },
    });
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          language: lang,
        },
      },
    });
  };

export default updateLanguage;
